import React, { useEffect, useRef } from 'react';
import { SEO, PageLayout, SqlRefresherCourseHero, Satisfactions, CourseCarousel, StudentSuccess } from "../components";
import { SQL_INST_DATA } from "../data/instructor";
import SqlInstructorBlock from "../components/instructorsBlock/sqlInstructorBlock";
import { useDispatch } from "react-redux";
import Payments from "../components/sqlRefresherCourse/sqlPayment";
import { regionPrice, formData } from "../data/sqlRefresherPayment";
import {
  getAnnouncement,
  getRegions,
  getCourseSchedules,
  getTestimonials,
  getSuccessStory,
  getCourses,
  getCourseCarouselData,
} from "../redux/action";

const SqlRefresherCoursePage = () => {
  const dispatch = useDispatch();
  const paymentSectionRef = useRef(null); // Create shared ref

  useEffect(() => {
    dispatch(getAnnouncement());
    dispatch(getRegions());
    dispatch(getTestimonials());
    dispatch(getCourses());
    dispatch(getCourseSchedules());
    dispatch(getSuccessStory());
    dispatch(getCourses());
    dispatch(getCourseCarouselData());
  }, []);

  // Reusable scroll function
  const scrollToPaymentSection = () => {
    if (paymentSectionRef.current) {
      const yOffset = -150; // Adjust this value to move higher
      const element = paymentSectionRef.current;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <>
      <SEO
        url="/refresher-course"
        openGraphType="website"
        schemaType="website"
        title="Refresher Course Page"
        description="We help you transition into tech seamlessly."
      />
      <PageLayout>
        <div className="bg-[#FCFCF5] w-full">
          <SqlRefresherCourseHero paymentSectionRef={paymentSectionRef} scrollToPaymentSection={scrollToPaymentSection} />
        </div>
        <Satisfactions paymentSectionRef={paymentSectionRef} scrollToPaymentSection={scrollToPaymentSection} />
        {/* <StudentSuccess title="Testimonials" /> */}
        <SqlInstructorBlock items={SQL_INST_DATA} />
        <Satisfactions satisfaction />
         <div ref={paymentSectionRef}>
        <Payments
          setIsEnrolOpen={() => {}}
          isEnrolOpen={false}
          regionPrice={regionPrice}
          showBanner={false}
          courseBanner={{ title: "Special Offer", description: "Limited Time Only" }}
          setShowBanner={() => {}}
        />
      </div>
        <CourseCarousel />
      </PageLayout>
    </>
  );
};

export default SqlRefresherCoursePage;
