import React from 'react';
import styles from './testimonial.module.css';
import { faith, Eva, Ola } from '../../../images';
import { fedrick } from '../../../images';

const Testimonial = ({ course }) => {
  const trimmedCourse = course.trim();

   return (
      <section
         className={`${styles['section_black_background']} ${styles['three_section_margin']}`}
      >
         <div className={styles['student_remarks']}>
            <div className={styles['remarks_text_and_link']}>
              <div className={styles['remarks_text']}>
                  <span className={styles['test-span']}>"</span>
                  {trimmedCourse === 'Product Management' ? (
                     "The Product Management program at TheSkillGarage transformed my career trajectory. The hands-on experience with real projects and mentorship from industry experts gave me the confidence to tackle complex product challenges. The program's focus on practical skills and current market demands helped me secure a position as a Product Manager within weeks of completion."
                     ) : trimmedCourse === 'Digital Marketing' ? ("The Digital Marketing Bootcamp was a game-changer for my career. The hands-on experience with real projects and mentorship from industry experts gave me the confidence to tackle complex product challenges. The program's focus on practical skills and current market demands helped me secure a position as a Product Manager within weeks of completion."
                  ) : trimmedCourse === 'Business Analysis' ? (
                     "The Business Analysis Bootcamp was a game-changer for my career. The hands-on experience with real projects and mentorship from industry experts gave me the confidence to tackle complex product challenges. The program's focus on practical skills and current market demands helped me secure a position as a Product Manager within weeks of completion."
                  ) : trimmedCourse === 'Data Analysis' ? (
                     "Enrolling in the Data Analytics program at TheSkillGarage has been a transformative experience. I’ve gained valuable skills in Excel, Power BI, SQL, and other Microsoft tools, which have equipped me to solve real-world problems confidently. Guided by the exceptional Coach Gbemi, the program’s hands-on approach made complex concepts easy to grasp and apply effectively. I’m thrilled with how much I’ve grown and excited about the endless opportunities this training has unlocked for me."
                  ) : trimmedCourse === 'Project Management' ? (
                     "The Project Management Bootcamp was an incredibly transformative experience, largely due to the exceptional quality of the tutors. Their personalized attention ensured that every participant, regardless of their background, gained maximum value from the bootcamp. They equipped us with practical strategies to overcome these obstacles, boosting our confidence as we prepare to apply our new skills. The mentorship didn't end with the bootcamp. The tutors made themselves available for follow-up questions and guidance, demonstrating their commitment to our long-term success as agile project managers."
                  ) : (
                     "When I joined TheSKillGarage, I had some knowledge of Business Analysis. However, I soon discovered that my knowledge was below par. I had to unlearn and relearn new concepts plus the project experience helped me to consolidate my business analysis knowledge. When I joined, I was told I would get a job in 3 months. I laughed because I had been on a BA job hunt for as long as I could remember. Anyways, cut the long story short, I got a job. Thanks to the relentless effort of TheSKillGarage"
                  )}
               </div>


               <a href="/testimonies" className={styles['yellow_linc']}>
                  See more testimonials
               </a>
            </div>

            <div className={styles['avatar_block']}>
               <div className={styles['img_block']}>
                 <img 
                           className={styles['test-img']} 
                           src={trimmedCourse === 'Product Management' ? fedrick : trimmedCourse === 'Project Management' ? Eva : trimmedCourse === 'Data Analysis' ? Ola : trimmedCourse === 'Business Analysis' ? faith : faith} 
                           alt="avatar" 
                           loading='lazy'
                        />
               </div>

               <div className={styles['label_block']}>
                 <div className={styles['label_title']}>
                  {trimmedCourse === 'Product Management' ? 'Fredrick Nnabuko' : trimmedCourse === 'Project Management' ? 'Eva Okupa' : trimmedCourse === 'Data Analysis' ? 'Olamilekan Shofela' : 'Faith Momoh'}
               </div>
               <div className={styles['label_text']}>
                  {trimmedCourse === 'Product Management' ? 'Product Management' : trimmedCourse === 'Project Management' ? 'Project Manager, Havas' : trimmedCourse === 'Data Analysis' ? 'Data Analyst' : 'Business Analyst'}
               </div>
               </div>
            </div>
         </div>
      </section>
   );
};


export default Testimonial;
