import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TSGBg from "../tsg-bg";
import PaymentCard from "../payments/cards";
import Button from "../button";
import CourseBanner from "../banner/course-banner";
import ToggleSwitch from "./baToogle";
import { regionPrice, formDatas } from "../../data/baPayments";

const BaPayment = React.forwardRef(({
  setIsEnrolOpen, isEnrolOpen, showBanner, courseBanner, setShowBanner,
}, ref) => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const { formData } = useSelector((state) => state);
  const [paymentOptionsWithSymbols, setPaymentOptionsWithSymbols] = useState([]);

  const handleToggle = (isOn) => {
    setIsFeatureEnabled(isOn);
  };

  useEffect(() => {
    if (formData.defaultRegion in regionPrice) {
      const userRegion = regionPrice[formData.defaultRegion];
      if (userRegion.cohorts && userRegion.payments) {
        const paymentOptions = userRegion.payments;
        let updatedPaymentOptions = [];

        if (isFeatureEnabled) {
          updatedPaymentOptions = paymentOptions.map((option) => ({
            ...option,
            price: option.defaultPrice,
            slashPrice: option.defaultSlash,
            symbol: formData.defaultRegion === "Europe - Africa" ? "€" : "CAD",
          }));
        } else {
          updatedPaymentOptions = paymentOptions.map((option) => ({
            ...option,
            price: option.price,

            slashPrice: option.slashPrice,
            symbol: formData.defaultRegion === "Europe - Africa" ? "£" : "USD",
          }));
        }

        setPaymentOptionsWithSymbols(updatedPaymentOptions);
      }
    }
  }, [isFeatureEnabled, formData.defaultRegion]);

  return (
    <TSGBg bgStyle="slopeLeftLow">
      <div ref={ref} className="w-screen lg:w-[calc(100vw-24px)] mt-8 xl:w-[1200px] flex flex-col items-center gap-10 lg:gap-[45px]">
        <div className="flex flex-col items-center gap-4">
          <h3 className="text-xl md:text-[28px] md:leading-9 text-[#1E1B2E] font-bold">
            Flexible and Affordable Pricing
          </h3>
          <Button size onClick={() => setIsEnrolOpen(true)}>Start week one for free</Button>
          <ToggleSwitch
            onColor="#F8D210"
            offColor="#ccc"
            isOnInitial={isFeatureEnabled}
            onToggle={handleToggle}
            payments={regionPrice[formData.defaultRegion].payments}
            regionPrice={formData.defaultRegion}
          />
        </div>
        
        {showBanner && (
          <CourseBanner
            showBanner={showBanner}
            setShowBanner={setShowBanner}
            {...courseBanner}
          />
        )}

        <div className="flex flex-col lg:flex-row w-full max:h-[1050px] h-full gap-4 lg:gap-16 justify-between">
          {paymentOptionsWithSymbols.map((priceOption) => (
            <PaymentCard
              key={priceOption.id}
              card={{
                ...priceOption,
                price: priceOption.price,
                symbol: priceOption.symbol,
                slashPrice: priceOption.slashPrice,
                userRegion: regionPrice[formData.defaultRegion]
              }}
              href={priceOption.href}
              href2={priceOption.href2}
              href3={priceOption.href3}
              setIsEnrolOpen={setIsEnrolOpen}
              isEnrolOpen={isEnrolOpen}
            />
          ))}
          
        </div>
      </div>
    </TSGBg>
  );
});

export default BaPayment;
