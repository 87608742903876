import React from 'react';
import styles from './referFriend.module.css';
import ReferForm from './referForm';

const ReferFriend = () => {
  const formSteps = [
    'Fill the form correctly and submit ',
    'Your referral receives an email',
    'Your referral enrols ',
    'You receive your reward. Simple as A-B-C.',
  ];
  return (
    <section className={styles['container']}>
      <div className={styles['leftDiv']}>
        <h1 className={styles['heading']}>Refer a friend and earn $50</h1>
        <p className={styles['subText']}>
          You get $50 for every successful sign-up. Thank you for choosing to contribute to our learning community by referring others. We appreciate your kind gesture.
        </p>

        {/* Stepper */}
        <div className={styles["points_section"]}>
            {formSteps.map((step, index) => (
                <div className={styles["point_item_row"]}>
                    <div className={styles["in_point_item_row"]}>
                      <div className={styles["point_icon"]}>
                      <div className={styles["disk_dot"]}></div>
                      </div>

                    <div className={styles["point_text"]}>{step}</div>
                    </div>
                    <div className={`${styles["vertical_line"]} ${index === 0 ? styles["first"] : ""}
                    ${index === formSteps.length - 1 ? styles["last"] : ""}`}></div>
                </div>
            ))} 
        </div>
        {/* Stepper */}

      </div>
      <div>
        <ReferForm />
      </div>
    </section>
  );
};
export default ReferFriend;
