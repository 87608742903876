import React, { useState, useEffect } from "react";
import styles from "./course-update.module.css";
import { Link } from "react-router-dom";
import { arrowDownBlack, locationIcon } from "../../../images";
import Button from "../../button";
import { setFormData } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import EnrolNow from "../../modals/enrol-now";
import { generateLongDuration, formatTimeRange } from "../../../utils/time";
import { filterRegionsByCourseTitle } from "../../../utils/constants";

const CourseUpdate = ({ course, allCourses, setIsEnrolOpen, setIsModalOpen, scrollToPayments }) => {
  // const [isEnrolOpen, setIsEnrolOpen] = useState(false);
  const [Open, setOpen] = useState(false);
  const [selected, setSelected] = useState(""); // for dropdown

  const { formData, regions } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    // Update selected region when formData.defaultRegion changes
    setSelected(formData.defaultRegion);
  }, [formData.defaultRegion]);

  const handleClick = (event, ctaUrl) => {
    event.preventDefault();
    setIsEnrolOpen(true);
    window.scrollTo(0, 0);
  };

  const handleClose = () => {
    setIsEnrolOpen(false);
    setOpen(false);
  };

  const getCohortsForRegion = (regionName) => {
    if (course && course.regionPrice) {
      const regionData = course.regionPrice[regionName];
      if (regionData && regionData.cohorts) {
        return regionData.cohorts;
      }
    }
    return [];
  };

  const cohortsForSelectedRegion = getCohortsForRegion(formData.defaultRegion);

  const availableRegions = filterRegionsByCourseTitle(allCourses, course.title);

  return (
    <section className={styles["section_no_background"]}>
      <div className={styles["body_section_no_background"]}>
        <section>
          <div className={styles["sss_title"]}>Upcoming Program Dates</div>

          {/* Region Selection */}
          {regions !== null && (
            <div className={styles["region_select_section"]}>
              {availableRegions.map((regionName) => (
                <span
                  key={regionName}
                  className={`${regionName === formData.defaultRegion ? styles["selected_region"] : null}`}
                  onClick={() => dispatch(setFormData({ defaultRegion: regionName }))}
                >
                  {regionName}
                </span>
              ))}
            </div>
          )}

          {/* Region Selection Dropdown for smaller screens */}
          <div className={styles["select_cont"]}>
            <div className={styles["select_dropdown"]}>
              <div className={styles["select"]} onClick={() => setOpen(!Open)}>
                <img src={locationIcon} alt="location-icon" loading="lazy"/>
                <span>{selected || "Select Region"}</span>
                <img className={styles["arrow"]} src={arrowDownBlack} alt="arrow black" loading="lazy"/>
              </div>
              {Open && (
                <div className={styles["select_options"]}>
                  {availableRegions.map((regionName) => (
                    <div
                      key={regionName}
                      className={`${styles["dropdown"]} ${regionName === selected ? styles["selected"] : null}`}
                      onClick={() => {
                        setSelected(regionName);
                        dispatch(setFormData({ defaultRegion: regionName }));
                        setOpen(false);
                      }}
                    >
                      {regionName}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Program Dates */}
          <div className={styles["program_dates"]}>
            {cohortsForSelectedRegion.length > 0 ? (
              cohortsForSelectedRegion.map((cohort) => (
                <div className={styles["program_date_point"]} key={cohort.id}>
                  <div className={styles["program_info_block"]}>
                    <div className={styles["program_info_items"]}>
                      <div className={styles["program_info_title"]}>
                        Course duration ({cohort.location} - {cohort.timezone} time zone)
                      </div>
                      <div className={styles["program_info_period"]}>
                        {generateLongDuration(cohort.startDate, cohort.endDate)}
                      </div>
                    </div>
                    {cohort.sessionList &&
                      cohort.sessionList.map((session, id) => (
                        <div className={styles["program_info_items"]} key={id}>
                          <div className={styles["program_info_title"]}>{session.day}</div>
                          <div className={styles["program_info_sessions"]}>
                            {`${formatTimeRange(session.from, session.to).formattedFrom} - ${formatTimeRange(
                              session.from,
                              session.to
                            ).formattedTo}  ${cohort.timezone}`}
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className={styles["program_button_block"]}>
             {cohort.ctaLabel.toLowerCase().startsWith('join waitlist') ? (
 
                <Button 
                  variant="secondary" 
                  className={styles["program_btn"]}
                  onClick={() => setIsEnrolOpen(true)}
                >
                  {cohort.ctaLabel}
                </Button>
        
            ) : (
              <Link 
                to=""
               onClick={scrollToPayments}
              >
                <Button 
                  variant="secondary" 
                  className={styles["program_btn"]}
                >
                  {cohort.ctaLabel}
                </Button>
              </Link>
            )}

                    {cohort.subtext ? <div className={styles["program_info_title"]}>{cohort.subtext}</div> : null}
                  </div>
                </div>

              ))            ) : (
              <div className={styles["title"]}>No Courses Available in this Region right now.</div>
            )}
          </div>
        </section>
      </div>
      {/* {isEnrolOpen && <EnrolNow onClose={handleClose} />} */}
    </section>
  );
};

export default CourseUpdate;
