export const FAST_TRACK_DATA = [
  {
    courseDuration: "Friday, 11 October 2024",
    courseTime: "7 PM - 10 PM EST",
    bonus: "Product Ownership",
    rating: "4.7 out of 5.0 star",
  },
  {
    courseDuration: "25th-26th Jan., 2025",
    courseTime: "9AM - 12PM EST",
    bonus: "AI Job Search Strategy",
    rating: "4PM - 7PM EST",
  },
  {
    courseDuration: "4th Jan 2025",
    courseTime: "4 Weeks",
    bonus: "None",
    rating: "Saturdays & Tuesdays",
  }
]