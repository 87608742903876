import {
  instructor,
  linkedIn2,
  Gbemi,
  instructorSignature,
  abb, abbott, telus, rogers, rbc, cocaCola, barclays
} from "../images"

export const INST_DATA = {
  mainImg: instructor,
  fullname: 'Micheal Apkan',
  socials: [
    linkedIn2
  ],
  positions: [
  ],
  description:
    [
      "A former university lecturer, a professional product manager , a seasoned product management coach, a certified business analyst, a certified scrum master, a certified product owner and ardent agile practitioner with 8+ years of digital product development experience across several industry verticals. Michael is a data-driven digital strategist with technical depth and a knack for intuitive user experience. His customer-focused approach to product development has helped create digital products that enhances the customer experience while delivering holistic stakeholder solutions and benefits. He enjoys bringing invaluable concepts from his engineering background to problem solving in the digital space.",
      "He has worked with global brands like TELUS, ABBOTT, ROGERS, OLG, BARCLAYS, RBC, LAURENTIAN BANK, RBC, ABB OPTICAL, COCA-COLA and a lot more across several industries such as Telecommunications, Financial Services, Retail, Education, E-commerce, Real Estate etc.",
      "Michael is also an associate fellow of the United Kingdom Higher Education Academy. He looks forward to preparing you for your new role as a business analyst."
    ],
  logos: [
    { url: "/#", image_url: abb, title: "Abb ltd."},
    { url: "/#", image_url: abbott, title: "Abbott U.S."},
    { url: "/#", image_url: barclays, title: "Barclays"},
    { url: "/#", image_url: cocaCola, title: "CocaCola"},
    { url: "/#", image_url: telus, title: "Telus"},
    { url: "/#", image_url: rbc, title: "Royal Bank of Canada"},
    { url: "/#", image_url: rogers, title: "Rogers"},
  ],
  signature: instructorSignature
};
export const SQL_INST_DATA = {
  mainImg: Gbemi,
  fullname: 'Olugbemi Akarigbo',
  socials: [
    linkedIn2
  ],
  positions: [
  ],
  description:
    [
      "Gbemi is an experienced Data Analyst and Analytics Engineer with a passion for teaching and sharing his knowledge. Throughout his career, he has worked with companies such as Wawanesa Insurance, LawDepot, and First Bank of Nigeria, as well as consulting for various organizations across industries including Financial Services, IT, Manufacturing, and E-commerce,  developing and implementing data solutions to drive decision-making. Gbemi is eager to bring his real-world experience into the classroom, making data concepts accessible and engaging, while helping students gain the skills they need to succeed",
    ],
  logos: [
   
  ],
  // signature: instructorSignature
};

