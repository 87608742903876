import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { bgTerms1, bgTerms2 } from '../../images'

const TopBanner = styled.div`
  background-color: #F8D210;
  background-image: url(${bgTerms1}), url(${bgTerms2});
  background-repeat: no-repeat, no-repeat;
  background-size: 98px 98px, 117px 117px;
  background-position: top 8px left 59px, bottom 18px right 45px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  width: 100%;
  height: 220px;
  gap: 8px;
  border-radius: 4px;

  @media only screen and (max-width: 992px) {
    margin-top: 40px;
    height: 244px;
    background-image: none;
    border-radius: 0;
  }
`

const InfoBlock = styled.div`
  width: 653px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: 992px) {
    width: 343px;
  }
`

const ContentWrap = styled.div`
  line-height: 3rem;
  margin-top: 2rem;

  h2, h4 {
    font-weight: 700;
  }

  h2 {
    font-size: 28px;
  }

  h4 {
    font-size: 16px;
  }

  p {
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }

  .decor {
    font-weight: 700;
    text-decoration: underline;
  }

  footer {
    line-height: 1.5rem;
    font-weight: 700;
    margin: 2.5rem 0;

    a {
      color: #333333;
    }
  }
`

const TermsCondition = () => {
  return (
    <div className='max-w-[1200px] m-auto'>
      <TopBanner>
        <InfoBlock>
          <h1 className='font-bold text-xl sm:text-4xl'>
            Terms & Conditions
          </h1>
        </InfoBlock>
        <p className='text-center'> Last Updated: September 25, 2024</p>
      </TopBanner>

      <div className='max-w-[800px] m-auto p-8'>

        <ContentWrap>
          <h2>Terms and Conditions for TheSkillGarage Ltd.</h2>
          <p>Welcome to TheSkillGarage Ltd. By enrolling in our courses, accessing our platform, and engaging with our services, you agree to abide by the following terms and conditions. These terms are legally binding and set the rules for your participation in our educational programs.</p>
          <p>
            <b>1. Introduction</b> <br />
           These terms and conditions apply to all courses, content, and services provided by TheSkillGarage Ltd., an educational platform for professional development and career advancement. By accessing our website and enrolling in our programs, you agree to comply with these terms.
          </p>

          <p>
           <b>2. Enrolment and Use of Services</b> <br />
           By enrolling in a course, you agree to adhere to all course guidelines and regulations as stipulated by TheSkillGarage.
            Unauthorized sharing, redistribution, or reproduction of course materials, videos, or any proprietary content is strictly prohibited.
            Course materials are for personal use only, and you are not permitted to distribute or commercialize them without explicit written consent from TheSkillGarage.

          </p>

        </ContentWrap>

        <ContentWrap>
          <h4>3. Media and Recordings</h4>
          <p>
          TheSkillGarage may record live classes, workshops, and sessions for internal use, marketing, or other media purposes.
          By participating in the courses, you grant TheSkillGarage the right to use, edit, and distribute recordings of your participation without your prior consent or compensation.
          You waive any rights to claim ownership or royalties from the use of such recordings.

        </p>

        <p>
          <b>4. Ownership of Work and Intellectual Property</b> <br />
          Any project or work undertaken during the course for the purpose of gaining work experience remains the sole property of TheSkillGarage, unless otherwise explicitly agreed in writing.
          Students will not have proprietorship, intellectual property rights, or ownership over any work produced during the course unless a separate agreement is made.
          Students agree not to claim ownership or commercial rights to any project, design, or intellectual property developed in collaboration with TheSkillGarage.

        </p>

        <p>
          <b>5. Non-Compete Clause</b> <br />
          Upon completion of any course or program at TheSkillGarage, you agree not to start or engage in a similar educational enterprise that competes with TheSkillGarage for a period of two (2) years from the date of graduation.
          Breaching this non-compete clause may result in legal action, including but not limited to seeking injunctive relief and financial damages.
          TheSkillGarage reserves the right to enforce non-compete consequences, including but not limited to monetary penalties and legal claims for loss of business opportunities.

        </p>

        <p>
          <b>6. License and Use of Materials</b> <br />
          All materials, resources, and tools provided by TheSkillGarage are proprietary and protected by intellectual property laws.

          You are not authorized to reproduce, distribute, or use TheSkillGarage materials for any purpose outside of the educational activities conducted within the course.
          Any breach of this condition will result in immediate termination of access to our platform, and TheSkillGarage may seek legal recourse, including claims for damages

        </p>

        <p>
          <b>7. Cookie Policy</b> <br />
          TheSkillGarage uses cookies to improve user experience and track website performance. Cookies are small text files stored on your device to optimize your interactions with the platform.
          By using our website, you consent to our use of cookies.
          Cookies are used to store preferences, track user behavior, and improve the overall user experience. We may also use cookies for targeted advertising and analytics.

        </p>

        <p>
          <b>8. <Link to="/privacy-policy" className="text-blue-600 hover:text-blue-800 underline">Privacy Policy</Link></b> <br />
          TheSkillGarage is committed to safeguarding your personal information. We collect personal data such as name, email address, and other details for course enrollment, user authentication, and communication purposes.

          We do not sell or share your personal data with third parties except for operational needs (e.g., payment processors, instructors, or technical support providers).
          Your data will be used in accordance with applicable data protection laws, and we will take reasonable precautions to protect it from unauthorized access.
          For more detailed information on how we collect, use, and protect your personal data, please refer to our Privacy Policy.

        </p>

        <p>
          <b>9. <Link to="/refund-policy" className="text-blue-600 hover:text-blue-800 underline">Refund Policy</Link></b> <br />
          Please refer to our <Link to="/refund-policy" className="text-blue-600 hover:text-blue-800 underline">refunds policy </Link> for all matters regarding refunds and cancellation.
        </p>

        <p>
          <b>10. Termination of Access</b> <br />
         TheSkillGarage reserves the right to terminate or restrict access to any user who violates these terms or engages in any conduct deemed harmful or disruptive to the learning environment.
Upon termination, the user must cease all use of materials and the platform, and no refund will be provided.

        </p>

        <p>
          <b>11. Liability</b> <br />
          TheSkillGarage is not liable for any indirect, incidental, or consequential damages arising from the use of our platform or course content.
We provide no guarantee regarding the accuracy, completeness, or success rate of the courses offered. All learning outcomes are subject to individual performance and external factors.

        </p>

        <p>
          <b>12. Amendments</b> <br />
          TheSkillGarage reserves the right to update and modify these terms and conditions at any time. Any changes will be communicated to enrolled students and posted on our website. Continued use of the platform implies acceptance of any updates.
        </p>

        <p>
          <b>13. Governing Law</b> <br />
          These terms and conditions are governed by and construed in accordance with the laws of [insert governing country or region]. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts in that jurisdiction.
        </p>

        <footer>
          For any questions or queries about these Terms or the Services in general, please do not hesitate to contact us at the following e-mail address: <a href="mailto:info@theskillgarage.com">info@theskillgarage.com</a>
        </footer>
        </ContentWrap>
      </div>
    </div>
  )
}

export default TermsCondition;