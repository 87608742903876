

export const regionPrice = {
  "Europe - Africa": {
    cohorts: ["cohort1", "cohort2"],
    payments: [
      {
        id: 10,
        price: 49,
        slashPrice: null,
        buttonLabel: "Start For Free",
        buttonUrl: "https://tsg-stage.vercel.app/free-course",
        description: "Register for our free fast-track course. No payment required",
        pillText: null,
        type: "Basic",
        defaultPrice: 0,
        defaultSlash: null,
      },
      {
        id: 5,
        price: 849,
        slashPrice: 1399,
        buttonLabel: "Make Full Payment",
        buttonUrl: "https://buy.stripe.com/fZe6qV8KY4WfaK415H",
        description: "Save 20% when you choose a one-time payment.",
        pillText: "Recommended",
        type: "Recommended",
        defaultPrice: 849,
        defaultSlash: 1399,
      },
      {
        id: 30,
        price: 299,
        slashPrice: 499,
        buttonLabel: "Pay in Installments ",
        buttonUrl: "https://buy.stripe.com/7sIbLf2mA88r3hC29K",
        description: "Save 15% when you make a three-time payment.",
        pillText: null,
        type: "Installment",
        defaultPrice: 299,
        defaultSlash: 499,
      },
    ],
  },
  "US & Canada": {
    cohorts: ["cohort3", "cohort4"],
    payments: [
      {
        id: 20,
        price: 229,
        slashPrice: null,
        buttonLabel: "Register Now",
        buttonUrl: "https://buy.stripe.com/cN29D71iwewPaK43ep",
        description: [
          "- Access to Live session",
          "- Access to recording",
          "- ⁠No project participation",
          "- ⁠Cannot upgrade to Bootcamp",
        ].join("\n"),
        pillText: null,
        type: "Basic",
        defaultPrice: 299,
        defaultSlash: null,
      },
      {
        id: 40,
        price: 299,
        slashPrice:null,
        buttonLabel: "Register Now",
        buttonUrl: "https://buy.stripe.com/8wMg1v3qE88r6tOg1c",
        description: [
          "- Access to Live Session",
          "- Access to recording",
          "- ⁠SQL Project participation",
          "- Can upgrade to Bootcamp",
        ].join("\n"),
        pillText: null,
        type: "Premium",
        defaultPrice: 399,
        defaultSlash:null,
      },
         {
        id: 15,
        price: 359,
        slashPrice:null,
        buttonLabel: "Register Now",
        buttonUrl: "https://buy.stripe.com/8wM7uZ3qE1K35pK5mz",
       description: [
  "- Access to Live Session",
  "- Access to Recording",
  "- SQL Project participation",
  "- SQL Project Defence & Review",
  "- Business Analysis Self-paced course",
  "- Can upgrade Bootcamp", 
].join('\n')
,
        pillText: "Recommended",
        type: "Platinum",
        defaultPrice: 499,
        defaultSlash: null,
      },
    ],
  },
};

export const formData = {
  defaultRegion: "US & Canada", // Change to "US & Canada" for testing other regions
};
