import { MatchDownIcon, MatchUpIcon } from "../images";

export const FAST_TRACK_SCHEDULE = [
  {
    id: 1,
    session: "Session 1",
    sessionTime: "Friday, 11 October 2024",
    sessionTitle: "Course Schedule",
    duration: "October 11th, 2024 7 pm - 10pm (EST)",
    time: "Time: 11 AM - 2 PM EST",
    description: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItems: [
      {points: "Introduction to Business Analysis"},
      {points: "The Business Analysis Job Functions"},
      {points: "Core Competencies of a Business Analyst"},
      {points: "Software Development Life Cycle (SDLC)"},
      {points: "Waterfall and Agile"},
    ],
  },
  {
    id: 2,
    session: "Session 2",
    sessionTime: "Saturday, 12 October 2024",
    sessionTitle: "Course Schedule",
    duration: "October 12th, 2022 4 pm - 7pm (EST)",
    time: "Time: 4 PM - 7 PM EST",
    description: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItems: [
      {points: "Requirements Life Cycle Management "},
      {points: "Requirements Elicitation"},
      {points: "Epics, User Stories and Acceptance Criteria"},
      {points: "Question and Answer Session"},
      {points: "Next Steps Guide"},
    ],
  }
]; 
FAST_TRACK_SCHEDULE.map((week, index) => ({
  ...week,
  icon: index % 2 === 0 ? MatchDownIcon : MatchUpIcon,
}));

export const FAST_TRACK_SCHEDULES = [
  {
    id: 1,
    session: "Session 1",
    sessionTime: "Saturday, 25th January 2025",
    sessionTitle: "Business Analysis Essentials",
    duration: "January 25th, 2025 9am - 12pm (EST)",
    time: "Time: 9 AM - 12 PM EST",
    description: "This session dives deep into essential concepts, proven methodologies, and strategic approaches that define effective business analysis. Whether you're a beginner looking to understand the fundamentals, an enthusiast looking to refresh key knowledge, or a professional aiming to refine your expertise, this session will provide the clarity and confidence you need to navigate the field. Get ready to refresh and enhance your skills and apply them to real-world scenarios with precision and impact!",
    pointItems: [
      {points: "Components of a Business Analysis Project "},
      {points: " Project Methodologies"},
      {points: "Stakeholder Management"},
      {points: "Elicitation and Documentation"},
      {points: " Business Process Modelling"},
      {points: "Scrum: JIra in action Practical Case Study"},
    ],
  },
  {
    id: 2,
    session: "Session 2", 
    sessionTime: "Sunday, 26 January 2025",
    sessionTitle: "Product Ownership + How to Win in the Job Market",
    duration: "January 26th, 2025 4pm - 7pm (EST)",
    time: "Time: 4 PM - 7 PM EST",
    description: "This session continues to explore the essentials of business analysis from the previous session but from the lens of the prevailing job market. We will discuss product ownership, how to win in the current job market, plus resume and interview essentials. ",
    pointItems: [
      {points: "Product ownership"},
      {points: "Outlook of the job market for Business Analysts "},
      {points: "Resume clinic - what to capture on your resume"},
      {points: "Using AI to aid your job applications "},
      {points: "Networking"},
      {points: "How to land an interview fast "},
      {points: "How to prepare for interviews"},
    ],
  },
];

FAST_TRACK_SCHEDULES.map((week, index) => ({
  ...week,
  icon: index % 2 === 0 ? MatchDownIcon : MatchUpIcon,
}));

export const SQL_TRACK_SCHEDULE = [ 
  {
    id: 1,
    session: "Week 1",
    sessionTime: "Saturday, 4th January 2025",
    sessionTitle: "Introduction to Databases & SQL Basics",
    duration: "Introduction to Databases & SQL Basics",
    time: "Time: 9 AM - 12 PM EST",
    description: "Learn the fundamentals of data, databases, data warehouses, and data lakes. Get hands-on with basic CRUD operations and SQL queries, including SELECT, SELECT DISTINCT, LIMIT, ORDER BY, and WHERE clauses. Master the LIKE operator and wildcard characters for effective data retrieval.",
    pointItems: [
      {points: "Introduction to Data & Databases "},
      {points: "Database, Data warehouse & Data Lake"},
      {points: "Basic CRUD Operations"},
      {points: "JSELECT, SELECT DISTINCT, LIMIT, ORDER BY"},
      {points: " WHERE Clause (Comparison Operators)"},
      {points: " LIKE Operator & Wildcard Characters analysis challenges"}, 
    ] ,
  },
  {
    id: 2,
    session: "Week 2",
    sessionTime: "Tuesday, 14 January 2025",
    sessionTitle: "Advanced SQL Techniques",
    duration: "Advanced SQL Techniques",
    time: "Time: 4 PM - 7 PM EST",
    description: "Explore aggregate functions (COUNT, SUM, AVG, MIN, MAX) and the GROUP BY clause for summarizing data. Use the HAVING clause and aliases for refined queries. Learn to work with variables, different types of joins (Inner, Left, Right, Full Outer), and UNION operations for combining datasets. ",
    pointItems: [
      {points: "Aggregate Functions (COUNT, SUM, AVG, MIN, MAX), GROUP BY"},
      {points: "HAVING Clause, Aliases."},
      {points: "Variables"},
      {points: "Inner Join, Left & Right Join, Full Outer Join"},
      {points: "Union, Union all"},
    ],
  }, 
  {
    id: 3,
    session: "Week 3",
    sessionTime: "Saturday, 18 January 2025",
    sessionTitle: "Advanced SQL Features",
    duration: "Advanced SQL Features",
    time: "Time: 4 PM - 7 PM EST",
    description: "Master the CASE statement and its usage with SUM(CASE) and COUNT(CASE) for conditional aggregations. Dive into subqueries, both nested and correlated. Learn to use temporary tables, Common Table Expressions (CTEs), and views to simplify complex queries and improve performance.",
    pointItems: [
      {points: "CASE Statement, SUM(CASE), COUNT(CASE)"},
      {points: " Subqueries 1 "},
      {points: "Subqueries 2"},
      {points: "Temporary Tables and CTEs "},
      {points: "Views"},
    ],
  }, 
  {
    id: 4,
    session: "Week 4",
    sessionTime: "Tuesday, 21 January 2025",
    sessionTitle: "SQL & Final Project",
    duration: "SQL Project Week",
    time: "Time: 4 PM - 7 PM EST",
    description: "Explore window functions for advanced data analysis, enabling calculations across rows without grouping. Get briefed on your project requirements and goals. Additionally, receive an introduction to GitHub for version control and collaboration on your data projects.",
    pointItems: [
      {points: "Window Functions"},
      {points: "Project Briefing "},
      {points: "Introduction to Github"},
    ],
  }, 

]; 

SQL_TRACK_SCHEDULE.map((week, index) => ({
  ...week,
  icon: index % 2 === 0 ? MatchDownIcon : MatchUpIcon,
}));