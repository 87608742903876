import React from "react";
import styles from "./course-tools.module.css";
import { COURSE_TOOLS, COURSE_TOOLS1, COURSE_TOOLS3, COURSE_TOOLS2 } from "../../../data";

const CourseTools = ({ course }) => {
  // Map course names to their respective tool arrays
  const toolMap = {
  'Business Analysis': COURSE_TOOLS1,
  'Data Analysis': COURSE_TOOLS2,
  'Project Management': COURSE_TOOLS3,
  'Product Management': COURSE_TOOLS
};


  // Select the appropriate tool array based on the course, defaulting to COURSE_TOOLS
  const tools = toolMap[course] || COURSE_TOOLS;

  return (
    <section className={styles.section_no_background}>
      <div className={styles.body_section_no_background}>
        <div className={styles.section_title}>Tools you will learn</div>
        
        <div className={styles.tools_body}>
          {tools.map((item, index) => (
            <div
              className={
                index === tools.length - 1
                  ? styles.tools_item_last
                  : styles.tools_item
              }
              key={index}
            >
              <img 
                src={item} 
                alt={`${course || 'Course'} tool ${index + 1}`} 
                loading="lazy"  // Enables lazy loading for each image
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CourseTools;
