export const MENU_FOOTER = [
  {
     name: 'For Students',
     multi: true,
     subMenu: [
      //  {
      //      name: 'Ala Carte',
      //      menu: [
      //         {
      //            name: 'Interview Prep',
      //            link: '',
      //         },
      //         {
      //            name: 'CV Clinic',
      //            link: '',
      //         },
      //         {
      //            name: 'Linkedin Optimization',
      //            link: '',
      //         },
      //         {
      //            name: 'Jira & Confluence',
      //            link: '',
      //         },
      //         {
      //            name: 'All Courses',
      //            link: '/all-course',
      //         },
      //      ],
      //   },
        {
           name: 'Business Analysis',
           menu: [
              {
                 name: 'Bootcamp',
                 link: '/courses/business-analysis',
              },
            //   {
            //      name: 'Self-Paced',
            //      link: '',
            //   },
              {
                 name: 'Crash Courses',
                 link: '/free-course',
              },
            //   {
            //      name: 'Project Only',
            //      link: '',
            //   },
           ],
        },
        
         {
            name: 'Product Management Certificate Course',
            menu: [
               {
               name: 'Bootcamp',
               link: '/courses/product-management',
            },
            //    {
            //    name: 'Project Only (Practicum)',
            //    link: '',
            // },
         ]
         },
       
        {
           name: 'Data Analysis Bootcamp ',
           menu: [
            //   {
            //      name: 'Crash Courses',
            //      link: '',
            //   },
              {
               name: 'Bootcamp',
               link: '/courses/data-analysis',
              }
           ],
        },
         // {
         //     name: 'Career Accelerator',
         //     menu: [
         //       { name: 'Practicum + Scrum-in-Action ',
         //        link: '',
         //       }
         //     ]
         // },
     ],
  },
//   {
//      name: 'For Company',
//      multi: false,
//      subMenu: [
//         {
//            name: 'Hire TSG Graduates',
//            link: '',
//         },
//         {
//            name: 'Post a job',
//            link: '',
//         },
//         {
//            name: 'Internships',
//            link: '',
//         },
//         {
//            name: 'Staff Training',
//            link: '',
//         },
//         {
//            name: 'Sponsor a Student',
//            link: '',
//         },
//      ],
//   },
  {
     name: 'Resources',
     multi: false,
     subMenu: [
        {
           name: 'Blog',
           link: '/resources/blog',
        },
      //   {
      //      name: 'Events',
      //      link: '',
      //   },
      //   {
      //      name: 'News',
      //      link: '',
      //   },
        {
           name: 'FAQ',
           link: '/resources/faq',
        },
      //   {
      //    name: "Live Q and A",
      //    link: "/resources/qa",
      //  },
      //   {
      //      name: 'Scholarship',
      //      link: '',
      //   },
        {
           name: 'Refer a Friend',
           link: '/resources/refer',
        },
     ],
  },
  {
     name: 'Corporate Info',
     multi: false,
     subMenu: [
        {
           name: 'About Us',
           link: '/about',
        },
        {
           name: 'Contact Us',
           link: '/contact',
        },
      //   {
      //      name: 'Work with us',
      //      link: '/work-skills',
      //   },
        {
           name: 'Partner with us',
           link: '/contact-partner',
        },
     ],
  },
];