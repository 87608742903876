import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TSGBg from "../tsg-bg";
import PaymentCard from "./cards";
import CourseBanner from "../banner/course-banner";
import ToggleSwitch from "./ToggleSwitch";

const Payments = React.forwardRef(({
  setIsEnrolOpen, isEnrolOpen, regionPrice, showBanner, courseBanner, setShowBanner
}, ref) => {
  const { formData } = useSelector((state) => state);
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false); // Track toggle state for currency switching
  const [paymentOptionsWithSymbols, setPaymentOptionsWithSymbols] = useState([]);

  useEffect(() => {
    if (formData.defaultRegion in regionPrice) {
      const userRegion = regionPrice[formData.defaultRegion];
      if (userRegion.cohorts && userRegion.payments) {
        const paymentOptions = userRegion.payments;
        let updatedPaymentOptions = [];

        // Adjust based on toggle state and region
        if (isFeatureEnabled) {
          if (formData.defaultRegion === "Europe - Africa") {
            updatedPaymentOptions = paymentOptions.map((option) => ({
              ...option,
              price: option.price || option.defaultPrice,
              slashPrice: option.slashPrice || option.defaultSlash,
              symbol: "€",
            }));
          } else if (formData.defaultRegion === "US & Canada") {
            updatedPaymentOptions = paymentOptions.map((option) => ({
              ...option,
              price: option.defaultPrice || option.price,
              slashPrice: option.defaultSlash || option.slashPrice,
              symbol: "c$",
            }));
          } else {
            // Handle Global and any other future regions with USD
            updatedPaymentOptions = paymentOptions.map((option) => ({
              ...option,
              price: option.price || option.defaultPrice,
              slashPrice: option.slashPrice || option.defaultSlash,
              symbol: "$",
            }));
          }
        } else {
          if (formData.defaultRegion === "Europe - Africa") {
            updatedPaymentOptions = paymentOptions.map((option) => ({
              ...option,
              price: option.defaultPrice || option.price,
              slashPrice: option.defaultSlash || option.slashPrice,
              symbol: "£",
            }));
          } else {
            // Default to USD for Global and any other regions when toggle is off
            updatedPaymentOptions = paymentOptions.map((option) => ({
              ...option,
              price: option.price || option.defaultPrice,
              slashPrice: option.slashPrice || option.defaultSlash,
              symbol: "$",
            }));
          }
        }

        setPaymentOptionsWithSymbols(updatedPaymentOptions);
      } else {
        console.log("Payment options or cohorts not found for the user's default region.");
      }
    }
  }, [isFeatureEnabled, regionPrice, formData.defaultRegion]);

  const handleToggle = (newState) => {
    setIsFeatureEnabled(newState); // Track toggle state for currency switching
  };

  return (
    <TSGBg bgStyle="slopeLeftLow">
      <div ref={ref} className="w-screen lg:w-[calc(100vw-24px)] xl:w-[1200px] flex flex-col items-center gap-10 lg:gap-[45px]">
        <h3 className="text-xl md:text-[28px] md:leading-9 text-[#1E1B2E] flex gap-4 font-bold flex-col items-center">
          Flexible and Affordable Pricing
          <ToggleSwitch
            onColor="#F8D210"
            offColor="#ccc"
            isOnInitial={isFeatureEnabled}
            onToggle={handleToggle}
            regionPrice={formData.defaultRegion} // Pass region to ToggleSwitch
          />
        </h3>
        {showBanner && (
          <CourseBanner
            showBanner={showBanner}
            setShowBanner={setShowBanner}
            {...courseBanner}
          />
        )}
        <div className="flex flex-col lg:flex-row w-full max:h-[1050px] h-full gap-4 lg:gap-16 justify-between">
         {paymentOptionsWithSymbols.map((priceOption) => (
          <PaymentCard
            key={priceOption.id}
            card={{
              ...priceOption, // Passing the entire updated price object
              price: priceOption.price, // Will be the price or defaultPrice depending on toggle
              symbol: priceOption.symbol, // Will be the appropriate currency symbol
              slashPrice: priceOption.slashPrice, // Will be the slashPrice or defaultSlash depending on toggle
              userRegion: regionPrice[formData.defaultRegion]
            }}
            setIsEnrolOpen={setIsEnrolOpen}
            isEnrolOpen={isEnrolOpen}
          />
        ))}
        </div>
      </div>
    </TSGBg>
  );
});

export default Payments;
