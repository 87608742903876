import React from "react";
import styles from "./course-analysis.module.css";
import Button from "../../button";

const CourseAnalysis = ({ setIsEnrolOpen, setIsModalOpen, course, scrollToPayments }) => {
  console.log("course", course);
  return (
    <section className={styles["section_no_background"]}>
      <div className={styles["start_today_block"]}>
        <div className={styles["tag_black"]}>{course.course_analyses[0]?.topPill}</div>

        <div className={styles["title_black"]}>
          {course.course_analyses[0]?.heading}
        </div>

        <div className={styles["parent_bonus_block"]}>
          <div className={styles["bonus_black"]}>
            <span>+ BONUS: </span>
            {course.course_analyses[0]?.subHeading}
          </div>

          <div className={styles["description_black"]}>
           {course.course_analyses[0]?.description}
          </div>
        </div>

       <div className={styles["button_row_black"]}>
  <Button 
    variant="secondary" 
    size
    className={styles["enrol_now_yellow"]} 
    onClick={() => course.title === "Business Analysis" 
      ? window.open("https://tidycal.com/theskillgarage1/skill-mapping", "_blank")
      : scrollToPayments()}
  >
    {course.title === "Business Analysis" ? "Book Skill Mapping Session" : "Enroll Now"}
  </Button>
  <Button 
    outline="white" 
    className={styles["download_brochure_black"]} 
    onClick={() => course.title === "Business Analysis"
      ? window.open("https://youtu.be/bA3TBzW-mlw?si=950o9k8xSAuy1RIU", "_blank")
      : setIsModalOpen(true)}
  >
    {course.title === "Business Analysis" ? "Watch Intro Video" : "Download Brochure"}
  </Button>
</div>

      </div>
    </section>
  );
};

export default CourseAnalysis;
