import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ToggleContainer = styled.div`
  position: relative;
  width: ${(props) => props.width || "44px"};
  height: ${(props) => props.height || "24px"};
  background-color: ${(props) => (props.isOn ? props.onColor : props.offColor)};
  border-radius: ${(props) => props.borderRadius || "100px"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isOn ? "flex-end" : "flex-start")};
  padding: 2px;
`;

const ToggleKnob = styled.div`
  width: ${(props) => props.knobWidth || "20px"};
  height: ${(props) => props.knobHeight || "20px"};
  background-color: ${(props) => props.knobColor || "#fff"};
  border-radius: ${(props) => props.knobRadius || "100px"};
  position: relative;
  transition: all 0.3s ease;
  opacity: ${(props) => (props.isOn ? 1 : 0.8)};
`;

const SqlToggleSwitch = ({
  onColor = "#F8D210",
  offColor = "#ccc",
  width = "44px",
  height = "24px",
  knobWidth = "20px",
  knobHeight = "20px",
  knobColor = "#fff",
  borderRadius = "100px",
  knobRadius = "100px",
  isOnInitial = false,
  onToggle,
  payments,
  regionPrice,
}) => {
  const [isOn, setIsOn] = useState(isOnInitial);
  const [prices, setPrices] = useState({
    current: "USD",
    default: "CAD",
  });

  useEffect(() => {
    if (payments) {
      setPrices({
        current: isOn ? "USD" : "USD",
        default: isOn ? "CAD" : "CAD",
      });
    }
  }, [isOn, payments]);

  const toggleSwitch = () => {
    setIsOn(!isOn);
    if (onToggle) {
      // Pass the new price state to parent component
      onToggle(!isOn, isOn ? "defaultPrice" : "price");
    }
  };

  return (
    <div className="flex items-center justify-between gap-2">
      <p
        className={`font-poppins text-base leading-6 text-left ${
          !isOn ? "font-bold" : "font-normal"
        }`}
      >
        {prices.current}
      </p>
      <ToggleContainer
        isOn={isOn}
        onClick={toggleSwitch}
        onColor={onColor}
        offColor={offColor}
        width={width}
        height={height}
        borderRadius={borderRadius}
      >
        <ToggleKnob
          isOn={isOn}
          knobWidth={knobWidth}
          knobHeight={knobHeight}
          knobColor={knobColor}
          knobRadius={knobRadius}
        />
      </ToggleContainer>
      <p
        className={`font-poppins text-base leading-6 text-left ${
          isOn ? "font-bold" : "font-normal"
        }`}
      >
        {prices.default}
      </p>
    </div>
  );
};

export default SqlToggleSwitch;
