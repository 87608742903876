export const regionPrice = {
  "Europe - Africa": {
    cohorts: ["cohort1", "cohort2"],
    payments: [
      {
        id: 30,
        price: 897,
        slashPrice: 1399,
        buttonLabel: "One Time Payment",
        buttonUrl: "https://buy.stripe.com/00g4iN5yMcoH05q7uP",
        description: [
          "➢ Product ownership training ",
"➢ Internship (Work Experience)",
"➢ Scrum training",
"➢ SQL video course",
"➢ 1-on-1 Resume/CV Clinic",
"➢ Interview Prep Session",
"➢ Interview Prep Videos",
"➢ Work Reference",
"➢ Life-time access to recordings",
"➢ Access to self-paced course",
"➢ 1-month on-the-job support",
        ].join('\n'),
        pillText: "Popular",
        type: "Platinum",
        defaultPrice: 1099,
        href: "https://buy.stripe.com/bIYcPj1iw74n4lGeXi",
        href2: "https://buy.stripe.com/6oE6qV3qEcoH2dy9D1",
        href3: "https://buy.stripe.com/7-time-installment",
        defaultSlash: 1399,
      },
      {
        id: 31,
        price: 1399,
        slashPrice: null,
        buttonLabel: "One Time Payment",
        buttonUrl: "https://buy.stripe.com/bIY02xe5i9cvf0k7uR",
        description: [
          "➢  Includes all Platinum features",
"➢ 4 months of on-the-job support",
"➢ 150 done-for-you job applications",
"➢ Weekly 1-on-1 mentorship with a professional",
"➢ LinkedIn optimization",
"➢ Advanced Linkedin Strategies",
"➢ Multiple interview prep sessions",
"➢ Discounts on Bootcamps & Mastery Courses",
"➢ Free refresher course access",
"➢ Priority Access",
        ].join('\n'),
        pillText: null,
        type: "Platinum PLUS ",
        defaultPrice: 1699,
        href: "https://buy.stripe.com/5kA5mR1iwcoH6tO9D0",
        href2: "https://buy.stripe.com/6oE3eJ5yM88r6tO7uU",
        href3: "https://buy.stripe.com/7-time-installment",
        defaultSlash: null,
      }
    ],
  },
  "US & Canada": {
    cohorts: ["cohort3", "cohort4"],
    payments: [
      // {
      //   id: 20,
      //   price: 419,
      //   slashPrice: null,
      //   buttonLabel: "One Time Payment",
      //   buttonUrl: "https://buy.stripe.com/eVa2aF9P2dsL6tO16k",
      //   description: [
      //     "➢ Live session",
      //     "➢ Life-time access to recordings",
      //     "➢ Program completion certificate ",
      //     "➢ Resume/CV Clinic",
      //     "➢ Includes product ownership",
      //   ].join("\n"),
      //   pillText: null,
      //   type: "Basic",
      //   defaultPrice: 599,
      //   href: "https://buy.stripe.com/eVa4iN1iwdsL7xS2as",
      //   href2: "https://buy.stripe.com/5-time-installment",
      //   href3: "https://buy.stripe.com/7-time-installment",
      //   defaultSlash: null,
      // },
      {
        id: 15,
        price: 1380,
        slashPrice: null,
        buttonLabel: "One Time Payment",
        buttonUrl: "https://buy.stripe.com/00g4iN5yMcoH05q7uP",
        description: [
                   "➢ Product ownership training ",
"➢ Internship (Work Experience)",
"➢ Scrum training",
"➢ SQL video course",
"➢ 1-on-1 Resume/CV Clinic",
"➢ Interview Prep Session",
"➢ Interview Prep Videos",
"➢ Work Reference",
"➢ Life-time access to recordings",
"➢ Access to self-paced course",
"➢ 1-month on-the-job support",
        ].join("\n"),
        pillText: "Popular",
        type: "Platinum",
        defaultPrice: 1890,
        href: "https://buy.stripe.com/bIYcPj1iw74n4lGeXi",
        href2: "https://buy.stripe.com/6oE6qV3qEcoH2dy9D1",
        href3: "https://buy.stripe.com/7-time-installment",
        defaultSlash: null,
      },
       {
        id: 40,
        price: 1779,
        slashPrice: null,
        buttonLabel: "One Time Payment",
        buttonUrl: "https://buy.stripe.com/bIY02xe5i9cvf0k7uR",
        description: [
                  "➢  Includes all Platinum features",
"➢ 4 months of on-the-job support",
"➢ 150 done-for-you job applications",
"➢ Weekly 1-on-1 mentorship with a professional",
"➢ LinkedIn optimization",
"➢ Advanced Linkedin Strategies",
"➢ Multiple interview prep sessions",
"➢ Discounts on Bootcamps & Mastery Courses",
"➢ Free refresher course access",
"➢ Priority Access",
        ].join("\n"),
        pillText: null,
        type: "Platinum PLUS",
        defaultPrice: 2499,
        href: "https://buy.stripe.com/5kA5mR1iwcoH6tO9D0",
        href2: "https://buy.stripe.com/6oE3eJ5yM88r6tO7uU",
        href3: "https://buy.stripe.com/7-time-installment",
        defaultSlash: null,
      },
    ],
  },
};

export const formDatas = {
  defaultRegion: "", // Change to "US & Canada" for testing other regions
};
