import React, { useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { bannerCloseIcon } from "../../images";
import { useNavigate } from "react-router-dom";
import styles from "./banner.module.css";
import AlertCopyBanner from "./alert-copy-banner";
import TimerPromoBanner from "./timer-promo-banner";

const Banner = ({
  showBanner,
  setShowBanner,
  message,
  dismissable = true,
  type = 'N/A',
  bannerColor = true,
  bannerUrl = null,
  btnUrl,
  code,
  icon,
  discount,
  title,
  btnLabel,
  btnType,
  dateAndTimeToExpire,
  expandedOnMobile
}) => {
  const navigate = useNavigate();

  
  // Define banner types for readability
  const isAlert = type === 'Alert';
  const isCopy = type === 'Copy';
  const isTimer = type === 'Timer';

  // Validate banner type and timer expiration
  useLayoutEffect(() => {
    if (!(isAlert || isCopy || isTimer) || 
        (isTimer && (!dateAndTimeToExpire || new Date(dateAndTimeToExpire) <= Date.now()))
    ) {
      setShowBanner(false);
    }
  }, [isAlert, isCopy, isTimer, dateAndTimeToExpire, setShowBanner]);

  // Handle close action
  const handleEscape = (e) => {
    e.stopPropagation();
    setShowBanner(false);
  };

  // Handle navigation for bannerUrl
  const handleBannerClick = () => {
    if ((isAlert || isCopy) && bannerUrl) navigate(bannerUrl);
  };

  if (!showBanner) return null;

  return (
    <div
      className={[
        styles.header,
        (isAlert || isCopy) && bannerUrl && styles.header_cursor,
        bannerColor && styles.banner_color,
        isTimer && styles.timer_header,
        isTimer && expandedOnMobile && styles.expanded_header
      ].filter(Boolean).join(' ')}
      onClick={handleBannerClick}
    >
      {(isAlert || isCopy) && (
        <AlertCopyBanner
          escapeable={dismissable}
          alert={isAlert}
          copy={isCopy}
          couponCode={code}
          copyIconType={icon}
          message={message}
        />
      )}

      {isTimer && (
        <TimerPromoBanner
          discount={`-${discount}%`}
          heading={title}
          btn_message={btnLabel}
          message={message}
          btn_type={btnType}
          couponCode={code}
          btnUrl={btnUrl}
          expiry_time={dateAndTimeToExpire}
          expanded={expandedOnMobile}
          setShowBanner={setShowBanner}
        />
      )}

      {dismissable && (
        <div className={styles.top_btn} onClick={handleEscape}>
          <img src={bannerCloseIcon} alt="Close icon" loading="lazy" />
        </div>
      )}
    </div>
  );
};

Banner.propTypes = {
  showBanner: PropTypes.bool,
  setShowBanner: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  dismissable: PropTypes.bool,
  bannerColor: PropTypes.bool,
  bannerUrl: PropTypes.string,
  type: PropTypes.string.isRequired,
  code: PropTypes.string,
  icon: PropTypes.string,
  discount: PropTypes.string,
  title: PropTypes.string,
  btnLabel: PropTypes.string,
  btnType: PropTypes.string,
  dateAndTimeToExpire: PropTypes.string,
  expandedOnMobile: PropTypes.bool
};

export default Banner;
