import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  SEO, PageLayout, CourseHeader, CourseInfo, CourseInfoBenefit, CourseTools,
  CourseSchedule, Testimonial, InstructorCarousel, CourseAnalysis, CourseCarousel,
  CourseUpdate, StudentSuccess, Faq, StartYourJourney, CourseTestimonial, CoursePlayer
} from "../components";
import EnrolNow from "../components/modals/enrol-now";
import DownloadCta from "../components/modals/download-cta";
import styles from "./courses.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getRegions, getCourses, setFormData, fetchThirdPartyRequest, getCourseBanners, getSuccessStory } from "../redux/action";
import Payments from "../components/payments";
import Payment from "../components/payments/ba-payments";
import { regionPrice, formData } from "../data/baPayments";

function Courses() {
  const [isEnrolOpen, setIsEnrolOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [course, setCourse] = useState(null);
  const [regionNames, setRegionNames] = useState([]);
  const [cachedData, setCachedData] = useState(null);

  const { formData, regions, courses, courseBanners } = useSelector((state) => state);
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showBanner, setShow] = useState(courseBanners?.[slug]?.showBanner);

  // Ref for the Payments component
  const paymentsRef = useRef(null);

  // Cache expiry logic
  const CACHE_EXPIRY_TIME = 1000 * 60 * 60; // 1 hour
  const getCachedData = () => {
    const cached = localStorage.getItem("regionCache");
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < CACHE_EXPIRY_TIME) {
        return data;
      }
    }
    return null;
  };

  const setCachedDataToLocalStorage = (data) => {
    localStorage.setItem("regionCache", JSON.stringify({ data, timestamp: Date.now() }));
  };

  // Fetch and update region based on IP
  const fetchCountryByIP = async () => {
    try {
      const data = await fetchThirdPartyRequest('https://ipapi.co/json/');
      const region = data?.country_name === "United States" || data?.country_name === "Canada"
        ? 'US & Canada'
        : 'Europe - Africa';
      
      dispatch(setFormData({ defaultRegion: region }));
      setCachedDataToLocalStorage(region);
    } catch (error) {
      console.error(error);
    }
  };

  // Initial data loading and region check
  useEffect(() => {
    const cachedRegion = getCachedData();
    if (cachedRegion) {
      dispatch(setFormData({ defaultRegion: cachedRegion }));
    } else {
      fetchCountryByIP();
    }

    if (regions === null) dispatch(getRegions());
    if (courses === null) dispatch(getCourses());
    dispatch(getCourseBanners());
    dispatch(getSuccessStory());
  }, [regions, courses]);

  // Find the course based on region and slug
  useEffect(() => {
    const findCourse = () => {
      if (courses && formData?.defaultRegion) {
        const defaultRegionCourses = courses[formData.defaultRegion]?.courses;
        const foundCourse = defaultRegionCourses?.find((c) => c.slug === slug) ||
          Object.values(courses).flatMap(region => region.courses).find((c) => c.slug === slug);

        if (foundCourse) {
          setCourse(foundCourse);
        } else {
          navigate("/404");
        }
      }
    };
    findCourse();
  }, [courses, formData.defaultRegion, slug, navigate]);

  // Scroll to Payments component
  const scrollToPayments = () => {
    if (paymentsRef.current) {
      const yOffset = -100;
      const element = paymentsRef.current;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  // Display spinner while loading
  if (!course) {
    return (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner}></div>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <>
      <SEO
        url={`/courses/${slug}`}
        openGraphType="website"
        schemaType="website"
        title={course?.title}
        description="We help you transition into tech seamlessly."
      />
      <PageLayout>
        <CourseHeader
          regions={regions}
          defaultRegion={formData.defaultRegion}
          course={course}
          allCourses={courses}
          bannerTile={course.title}
          bannerMessage={course.courseDescription}
          setIsModalOpen={setIsModalOpen}
          scrollToPayments={scrollToPayments}
        />
        <CourseInfo course={course} />
        <CourseInfoBenefit setIsEnrolOpen={setIsEnrolOpen} course={course} scrollToPayments={scrollToPayments} />
        {course.videoLink && <CoursePlayer course={course.videoLink} />}
        <CourseTestimonial course={course.title} />
        <CourseTools course={course.title} />
        {course.courseSchedules && <CourseSchedule items={course.courseSchedules} />}
        <CourseAnalysis setIsEnrolOpen={setIsEnrolOpen} setIsModalOpen={setIsModalOpen} scrollToPayments={scrollToPayments} course={course} />
        {course.successStories.length > 0 && <StudentSuccess course={course.title} />}
        <CourseUpdate course={course} allCourses={courses} scrollToPayments={scrollToPayments} setIsEnrolOpen={setIsEnrolOpen} setIsModalOpen={setIsModalOpen} />
        {course.regionPrice && (
          <>
            {course.title.includes("Business Analysis") ? (
              <Payment
              ref={paymentsRef}
                setIsEnrolOpen={setIsEnrolOpen}
                isEnrolOpen={false}
                regionPrice={regionPrice}
                showBanner={false}
                courseBanner={{ title: "Special Offer", description: "Limited Time Only" }}
                setShowBanner={() => {}}
              />
            ) : (
              <Payments
                ref={paymentsRef}
                setIsEnrolOpen={setIsEnrolOpen}
                isEnrolOpen={isEnrolOpen}
                regionPrice={course.regionPrice}
                showBanner={showBanner}
                courseBanner={courseBanners?.[slug]}
                setShowBanner={setShow}
              />
            )}
          </>
        )}
        {course.course_instructors && <InstructorCarousel yellow={true} items={course.course_instructors} />}
        <Faq course={course} />
        <CourseCarousel exempt={course.id} />
        <StartYourJourney />
      </PageLayout>
      {isEnrolOpen && <EnrolNow Close={() => setIsEnrolOpen(false)} course={course} regionNames={regionNames} setIsEnrolOpen={setIsEnrolOpen} allCourses={courses} />}
      {isModalOpen && <DownloadCta onClose={() => setIsModalOpen(false)} courses={course} />}
    </>
  );
}

export default Courses;
